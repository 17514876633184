import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import HeaderAd from "../components/ads/header-ad";

// markup
const ContactUsPage = () => {
  return (
    <Layout>
      <Seo title={`Online Hangman`} />
      <div>
        <HeaderAd />
      </div>
      <div className={"container mx-auto px-4 pb-6 py-6"}>
        <h3 className={"text-sm font-gray-800 mb-0"}>Something on your mind?</h3>
        <h1 className={"text-4xl font-bold mb-10"}>Contact Us</h1>
        <div className={"w-full p-4  bg-white"}>
          <p>Coming soon</p>
        </div>
      </div>
    </Layout>
  );
};

export default ContactUsPage;
